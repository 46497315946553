import {AuthModel, UserModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'auth-kiloit'
const USER_LOCAL_STORAGE_KEY = "current-user";
const MERCHANT_LOCAL_STORAGE_KEY = "merchant";
const getAuth = (key?: string): AuthModel | undefined => {

    if (!sessionStorage) {
        return
    }

    const lsValue: string | null = sessionStorage.getItem(key ?? AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
        return
    }

    try {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth) {
            // You can easily check auth_token expiration also
            return auth
        }
    } catch (error) {
        console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
}

const setAuth = (auth: AuthModel, key?: string) => {
    if (!sessionStorage) {
        return
    }

    try {
        const lsValue = JSON.stringify(auth)
        sessionStorage.setItem(key ?? AUTH_LOCAL_STORAGE_KEY, lsValue)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
    }
}

const setCurrentUser = (user: UserModel) => {
    sessionStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
}

const setMerchant = (payload: UserModel) => {
    sessionStorage.setItem(MERCHANT_LOCAL_STORAGE_KEY, JSON.stringify(payload));
}

const getMerchant = (): UserModel | null => {
    const merchant = sessionStorage.getItem(MERCHANT_LOCAL_STORAGE_KEY);

    if (!merchant) {
        return null;
    }
    return JSON.parse(merchant);
}

const getCurrentUser = (): UserModel | null => {
    const currentUser = sessionStorage.getItem(USER_LOCAL_STORAGE_KEY);
    if (!currentUser) {
        return null;
    }
    return JSON.parse(currentUser);
}

const removeAuth = () => {
    if (!sessionStorage) {
        return
    }

    try {
        sessionStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
        sessionStorage.removeItem(USER_LOCAL_STORAGE_KEY)
    } catch (error) {
        console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
    }
}

const parseToken = () => {
    const token = getAuth()?.accessToken;
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};

const getUserId = () => {
    return parseToken().id;
};

const getPermissions = () => {
    return parseToken().permissions;
};

const getUserCode = () => {
    return parseToken().code;
};

const getUser = () => {
    return parseToken();
};

const getRole = (isSub = false) => {
    let user = getUser();
    if (isSub && user.roleCode === "sub-account") return user.parentRoleCode;
    return user?.roleCode;
};

const getSessionStorage = (key: string) => {
    return sessionStorage.getItem(key);
}

export {
    getAuth,
    setAuth,
    setCurrentUser,
    getCurrentUser,
    removeAuth,
    AUTH_LOCAL_STORAGE_KEY,
    USER_LOCAL_STORAGE_KEY,
    getUserId,
    getPermissions,
    getUserCode,
    getRole,
    getUser,
    parseToken,
    setMerchant,
    getSessionStorage,
    getMerchant
}
