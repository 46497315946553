import React, {useEffect} from "react";
import BrowseAppsDropdown from "./BrowseAppsDropdown";
import ProfileDropdown from "./ProfileDropdown";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import NotificationDropdown from "./NotificationDropdown";
import {useAuth} from "../../../app/modules/auth/core/action";
import {useAddProduct} from "../../../app/modules/business/components/product-suite/add-product/core/actions";
import {getAuth} from "../../../app/modules/auth";
import {useAppSelector} from "../../../app/redux/hooks/hook";
import {authRedirectUrl} from "../../../app/constants/redirect";

const Header = () => {
    const {business: {location}} = useAppSelector(state => state.business);
    const {currentUser} = useAuth();
    const auth = getAuth()
    const {getAllMerchants} = useAuth();
    const {getAllInstallApp} = useAddProduct();

    useEffect(() => {
        if (currentUser) {
            getAllMerchants();
            getAllInstallApp();
        }
        // eslint-disable-next-line
    }, [currentUser, location])

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="">
                                <Link to="/">
                                    <h2 className="text-black" style={{fontSize: "32px"}}>KiloBoard</h2>
                                </Link>
                            </div>
                        </div>

                        {
                            !auth?.accessToken ?
                                <a className="btn px-4 py-1 rounded-5 text-white"
                                   href={`${authRedirectUrl.signIn}?redir=${window.location.origin}`}
                                   style={{background: "#005D7B"}}>Sign In</a> :
                                <div className="d-flex align-items-center">

                                    {/* NotificationDropdown */}
                                    <NotificationDropdown/>

                                    {/* Browse by Apps Dropdown */}
                                    <BrowseAppsDropdown/>

                                    {/* ProfileDropdown */}
                                    <ProfileDropdown/>
                                </div>
                        }
                    </div>
                </div>
            </header>

            <div
                id="removeNotificationModal"
                className="modal fade zoomIn"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id="NotificationModalbtn-close"
                            ></button>
                        </div>

                        <div className="modal-body p-md-5">
                            <div className="text-center">
                                <div className="text-danger">
                                    <i className="bi bi-trash display-4"></i>
                                </div>
                                <div className="mt-4 fs-15">
                                    <h4 className="mb-1">Are you sure ?</h4>
                                    <p className="text-muted mx-4 mb-0">
                                        Are you sure you want to remove this Notification ?
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <Button
                                    type="button"
                                    variant="light"
                                    className="w-sm"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </Button>
                                <Button
                                    type="button"
                                    variant="danger"
                                    className="w-sm"
                                    id="delete-notification"
                                >
                                    Yes, Delete It!
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Header;
