import {createSlice} from '@reduxjs/toolkit'

// Define a type for the slice state
interface settingState {
    currentPassword: boolean,
    newPassword: boolean,
    confirmPassword: boolean
}


// Define the initial state using that type
const initialState: settingState = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        modifyShowPassword: (state, action) => {
            const modal = action.payload.type
            // @ts-ignore
            state[modal] = action.payload.value;
        }
    }
})

export const {
    modifyShowPassword
} = settingSlice.actions


export default settingSlice.reducer
