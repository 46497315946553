import React, {FC} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {App} from "../App";
import {PrivateRoutes} from "./PrivateRoutes";
import VerifyAuth from "../modules/auth/components/VerifyAuth";
import LandingPage from "../modules/landingpage/LandingPage";
import {useAuth} from "../modules/auth/core/action";
import {getUser} from "../modules/auth";

const {PUBLIC_URL} = process.env;

const AppRoutes: FC = () => {
    const {auth: {user}} = useAuth();

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App/>}>
                    {!getUser() ? (
                        <>
                            <Route path="auth/verify" element={<VerifyAuth />} />
                            <Route path="auth/sign-in" element={<LandingPage/>}/>
                            <Route path="*" element={<Navigate to="/auth/sign-in"/>}/>
                        </>
                    ) : (
                        <>
                            <Route path='/*' element={<PrivateRoutes/>}/>
                            <Route path="auth/verify" element={<VerifyAuth/>} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export {AppRoutes};
