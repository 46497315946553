import React from 'react'
import {Outlet} from 'react-router-dom'
import {AuthInit} from './modules/auth'
import {I18nProvider} from "../_kiloit/i18n/i18nProvider";

const App = () => {
    return (
        <I18nProvider>
            <AuthInit>
                <Outlet/>
            </AuthInit>
        </I18nProvider>
    )
}

export {App}
