import {FC} from 'react'
import {useLang} from './KiloITi18n'
import {IntlProvider} from 'react-intl'

import deMessages from './messages/de.json'
import enMessages from './messages/en.json'
import esMessages from './messages/es.json'
import frMessages from './messages/fr.json'
import jaMessages from './messages/ja.json'
import zhMessages from './messages/zh.json'
import {WithChildren} from "../helpers/react18MigrationHelpers";

const allMessages = {
    de: deMessages,
    en: enMessages,
    es: esMessages,
    fr: frMessages,
    ja: jaMessages,
    zh: zhMessages,
}

const I18nProvider: FC<WithChildren> = ({children}) => {
    const locale = useLang()
    const messages = allMessages[locale]

    return (
        <IntlProvider locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export {I18nProvider}
