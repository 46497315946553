import React from "react";
import {createRoot} from "react-dom/client";
import "./_kiloit/assets/scss/themes.scss";
import reportWebVitals from "./reportWebVitals";
import {AppRoutes} from "./app/routing/AppRoutes";
import store from "./app/redux/store";
import {Provider} from "react-redux";
import {setUpAxios} from "./_kiloit/helpers/axiosHelper";

setUpAxios();
const container = document.getElementById('root');

if (container) {
    createRoot(container).render(
        <Provider store={store}>
                <AppRoutes/>
        </Provider>
    )
}
reportWebVitals();
