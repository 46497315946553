import React, {FC, useCallback, useState} from "react";
import Cropper from "react-easy-crop";
import {Area, Point} from "react-easy-crop/types";
import {useHelper} from "../../../_kiloit/helpers/crud-helper/core/action";

interface Props {
    image: string;
    setFile: any;
}

const CustomCropper:FC<Props> = ({image, setFile}) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const {getCroppedImage} = useHelper();

    const generateCroppedImage = useCallback(async (croppedAreaPixels: Area) => {
        try {
            if (croppedAreaPixels) {
                const croppedImage = await getCroppedImage(image, croppedAreaPixels);
                setFile(croppedImage);
            }
        } catch (e) {
            console.error('Error generating cropped image:', e);
        }
    }, [image, getCroppedImage, setFile]);
    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        return generateCroppedImage(croppedAreaPixels);
    }, [generateCroppedImage]);

    return (
        <React.Fragment>
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={360 / 235}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />
        </React.Fragment>
)
    ;
};

export default CustomCropper
