import {fileEndpoint} from "./endpoint";
import {instanceApi} from "../../../../_kiloit/helpers/axiosHelper";

export const reqUploadFile = (payload: any, appId: number) => {
    return instanceApi.post(fileEndpoint.upload, payload, {
        headers: {
            "Content-Type": "multipart/form-data",
            "X-App-ID": `${appId}`
        }
    });
}

export const reqDeleteFile = (payload: string) => {
    return instanceApi.delete(`${fileEndpoint.delete}?url=${payload}`);
}