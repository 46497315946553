import React from "react";
import AppCard from "./components/AppCard";
import {Link} from "react-router-dom";
import {useAddProduct} from "../business/components/product-suite/add-product/core/actions";
import NoAppInstalled from "./components/NoAppInstalled";
import {Spinner} from "react-bootstrap";

const AppBoard = () => {
    const {installedProduct, loading} = useAddProduct();

    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <div className="vh-85 d-flex justify-content-center align-items-center">
                        <Spinner variant="main"/>
                    </div>
                ) : (
                    <>
                        {!loading && installedProduct?.length === 0 ? <NoAppInstalled/> : (
                            <div className="d-flex flex-column min-vh-85">
                                <h4 className="text-black mb-4">Your Products</h4>
                                <div className="d-flex justify-content-start align-items-start gap-4 flex-wrap">
                                    {installedProduct?.map((product) => (
                                        <AppCard key={product.id} name={product.name ?? ""} icon={product.icon ?? ""}/>
                                    ))}
                                </div>
                                <div className="mt-auto py-4">
                                    <p className="mb-0">
                                        Do you want to install more products? <Link style={{color: "#4085AD"}} to="/product-suite">Click here!</Link>
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    )
}

export default AppBoard