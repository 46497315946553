import React, {FC, useEffect, useState} from "react";
import ModalWrapper from "../../../../../widgets/ModalWrapper";
import GoogleMaps from "./GoogleMaps";
import {svgUrl} from "../../../../../../../_kiloit/helpers";
import {Button, Image} from "react-bootstrap";
import SearchBar from "../../../../../widgets/SearchBar";
import {LocationModel} from "../../../../core/model";
import {googleMapsApi} from "../../../../../../constants/google-maps";

interface Props {
    show: boolean;
    onHide: any;
    currentLocation: LocationModel | null;
    onConfirmLocation: (location: { lat: number; lng: number }, address: string) => void;
}

const MapModal: FC<Props> = ({ show, onHide, currentLocation, onConfirmLocation }) => {
    const [tempLocation, setTempLocation] = useState<LocationModel | null>(null);

    const onConfirm = async () => {
        if (tempLocation) {
            const {lat, lng} = tempLocation;
            const response = await fetch(googleMapsApi(lat, lng));
            const data = await response.json();
            const address = data.results && data.results.length > 0 ? data.results[0].formatted_address : "Unknown location";
            onConfirmLocation(tempLocation, address);
        }
        onHide();
    }

    useEffect(() => {
        setTempLocation(currentLocation);
    }, [currentLocation, show]);

    return (
        <ModalWrapper
            size="xl"
            show={show}
            onHide={onHide}
            centered={true}
            dialogClassName="modal-xl"
            contentClassName="rounded-4 border-0 overflow-hidden"
            modalBody={
                <div className="postion-relative">
                    <GoogleMaps
                                height="80vh"
                                currentLocation={tempLocation} setCurrentLocation={setTempLocation}
                                zoomControl={false} fullScreenControl={false}
                                mapTypeControl={false} streetViewControl={false}
                                enabledClick={true}
                                enabledCurrentLocation={true}
                    />
                    <span className="position-absolute top-0 end-0 m-2">
                        <Image className="cursor-pointer" onClick={onHide} src={svgUrl("cancel.svg")}/>
                    </span>
                    <span style={{width: "35%"}} className="position-absolute top-0 start-0 m-2">
                        <SearchBar placeholder="Search Google Maps"/>
                    </span>
                    <span className="position-absolute bottom-0 end-0 m-3">
                        <Button onClick={onConfirm} variant="main" className="px-5 py-1">Done</Button>
                    </span>
                </div>
            }
        />
    )
}

export default MapModal
