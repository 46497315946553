import React, {FC, useEffect, useState} from "react";
import {Form, ProgressBar} from "react-bootstrap";

interface Props {
    title?: string;
    variant?: string;
    duration?: number;
    loading?: boolean;
}

const LoadingProgress:FC<Props> = ({title = "", variant = "main", duration = 1500, loading = false}) => {
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        let progressInterval: any;
        if (loading) {
            if (progress < 100) {
                progressInterval = setInterval(() => {
                    setProgress((prevProgress) => {
                        const increment = Math.round(100 / (duration / 100));
                        return Math.min(prevProgress + increment, 100);
                    });
                }, 100);
            } else {

                clearInterval(progressInterval);
            }
        } else {
            setProgress(100);
            clearInterval(progressInterval);
        }
        return () => clearInterval(progressInterval);
        // eslint-disable-next-line
    }, [duration, progress]);

    return (
        <React.Fragment>
            <Form.Label>{title}</Form.Label>
            <ProgressBar variant={variant} now={progress} />
            <div className="text-end text-main mt-2">
                <p>{progress === 100 && loading ? "Storing file...! Please wait!" : `${progress}% completed`}</p>
            </div>
        </React.Fragment>
    )
}

export default LoadingProgress