import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

const StepProgressBar = () => {
	const path = useLocation().pathname.split('/').filter(Boolean).pop();

	const getStep = () => {
		switch (path) {
			case "contacts":
				return 0;
			case "address-and-banner":
				return 33;
			case "review":
				return 66;
			case "completed":
				return 100;
			default:
				return 0;
		}
	};

	const dotsAndText = [
		{ dot: 0, text: "Contacts", leftMargin: "-22px" },
		{ dot: 33, text: "Address & Banner", leftMargin: "-50px" },
		{
			dot: 66,
			text: "Review",
			leftMargin: "-14px",
			message: "Hey! You’re almost complete.",
		},
		{ dot: 100, text: "Completed", leftMargin: "-32px" },
	];

	const progress = getStep();
	return (
		<Fragment>
			<div className="d-flex justify-content-center justify-content-lg-end mb-5 ms-2 me-2 me-lg-4">
				<div
					className="position-relative mb-2 w-100"
					style={{ maxWidth: "410px" }}
				>
					<div className="progress" style={{ height: 2 }}>
						<div
							className="progress-bar"
							role="progressbar"
							style={{ width: `${progress}%`, backgroundColor: "#4085AD" }}
						/>
					</div>

					{dotsAndText.map((item, index) => (
						<div
							key={index}
							className="position-absolute rounded-circle"
							style={{
								width: 15,
								height: 15,
								top: -7,
								left: `${item.dot}%`,
								backgroundColor: progress >= item.dot ? "#4085AD" : "#ccc",
							}}
						>
							{progress === 66 && (
								<div
									style={{ top: -30, left: -80 }}
									className="text-nowrap position-absolute"
								>
									{item?.message}
								</div>
							)}
							<div
								style={{
									left: item.leftMargin,
									color: progress >= item.dot ? "#4085AD" : "#ccc",
								}}
								className="text-nowrap position-absolute mt-3"
							>
								{item.text}
							</div>
						</div>
					))}
				</div>
			</div>
		</Fragment>
	);
};

export default StepProgressBar;
