import React, {FC, useCallback} from "react";
import {GoogleMap, Marker, useLoadScript} from "@react-google-maps/api";
import {LocationModel} from "../../../../core/model";
import {googleMapsApiKey} from "../../../../../../constants/google-maps";

interface Props {
    currentLocation: LocationModel | null;
    setCurrentLocation?: (location: LocationModel) => void;
    zoomControl?: boolean;
    fullScreenControl?: boolean;
    mapTypeControl?: boolean;
    streetViewControl?: boolean;
    enabledClick?: boolean;
    height? : string
    enabledCurrentLocation?: boolean;
}

const GoogleMaps: FC<Props> = ({
                                   currentLocation,
                                   setCurrentLocation,
                                   zoomControl = true,
                                   fullScreenControl = false,
                                   mapTypeControl = true,
                                   streetViewControl = true,
                                   enabledClick = false,
                                   height = "504px",
                                   enabledCurrentLocation = false
                               }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey,
    });

    const onMapClick = useCallback(
        (event: any) => {
            if (setCurrentLocation) {
                const lat = event.latLng.lat();
                const lng = event.latLng.lng();
                const location = { lat, lng }
                setCurrentLocation(location);
            }
        },
        [setCurrentLocation]
    );


    const showCurrentLocation = useCallback(async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    const location = {lat: latitude, lng: longitude};
                    if (setCurrentLocation) {
                        setCurrentLocation(location);
                    }
                },
                (error) => {
                    console.error("Error getting user's location:", error);
                }
            );
        }
    }, [setCurrentLocation]);

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <GoogleMap
            mapContainerClassName="border-0"
            mapContainerStyle={{width: "100%", height: height, outline: "0"}}
            zoom={15}
            center={currentLocation || {lat: 11.5564, lng: 104.9282}}
            onClick={enabledClick ? onMapClick : undefined}
            options={{
                zoomControl: zoomControl,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                },
                fullscreenControl: fullScreenControl,
                mapTypeControl: mapTypeControl,
                streetViewControl: streetViewControl,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                },
            }}
        >
            {currentLocation && <Marker position={currentLocation}/>}
            {enabledCurrentLocation && (
                <div style={{width: 42, height: 42, left: "37.5%"}} className="cursor-pointer position-absolute bg-main rounded-2 z-1 text-white top-0 m-2 d-flex justify-content-center align-items-center rounded-circle" onClick={showCurrentLocation}>
                    <i className="mt-1 bi bi-geo-alt-fill"></i>
                </div>
            )}
        </GoogleMap>
    );
};

export default GoogleMaps
