import React, { FC } from 'react';
import helpIcon from "../../../../../../../_kiloit/assets/images/add-product/help.svg";

interface HelpBtnProps {
    onClick: () => void; // Function that takes no parameters and returns void
}

const HelpBtn: FC<HelpBtnProps> = ({ onClick }) => {
    return (
        <button className="btn p-0" onClick={onClick} style={{ color: "#CCCCCC", cursor: "pointer", fontSize:"12px" }}>
            Help <img src={helpIcon} alt="Help Icon" />
        </button>
    );
};

export default HelpBtn;
