import React, {useEffect} from "react";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import AddProductPage from "./add-product/AddProductPage";
import BcAndContactPage from "./bc-and-contacts/BcAndContactPage";
import CompletedPage from "./completed/CompletedPage";
import {useAddProduct} from "./add-product/core/actions";
import AddressAndBannerPage from "./address-and-banner/AddressAndBannerPage";
import ReviewPage from "./review/ReviewPage";
import {useBusiness} from "../../core/action";

const ProductSuitePage = () => {
    const navigate = useNavigate();
    const {selectedProduct} = useAddProduct();
    const {isCompleted} = useBusiness();
    const {pathname} = useLocation();

    useEffect(() => {
        if (!selectedProduct && !isCompleted && pathname !== "/product-suite/completed") {
            navigate("/product-suite/add-product");
        }
        // eslint-disable-next-line
    }, [selectedProduct, isCompleted, pathname]);

    return (
        <Routes>
            <Route path="add-product" element={<AddProductPage/>}/>
            <Route path="contacts" element={<BcAndContactPage/>}/>
            <Route path="address-and-banner" element={<AddressAndBannerPage/>}/>
            <Route path="review" element={<ReviewPage/>}/>
            <Route path="completed" element={<CompletedPage/>}/>
            <Route index element={<Navigate to="/product-suite/add-product"/>}/>
        </Routes>
    )
}

export default ProductSuitePage;