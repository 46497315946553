import image from "./luna-web-04.width-800 1.png"

export const business = {
    businessName : "HY Chahan",
    businessCategory : "Coffee Shop",
    contactPerson : {
        name : "Rours Channa",
        position : "Sale Manager",
        phone : "0123456789",
        email : "5VQZb@example.com"
    },
    businessAddress : {
        address : "TK Roundabout",
        map : "https://maps.app.goo.gl/eP4WKQK2X2CRoxwK6",
        embedmap : `https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7817.435595645056!2d104.8923741!3d11.5720768!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310951828421b32b%3A0xa65a8d05f54f94bb!2sToul%20Kork!5e0!3m2!1sen!2skh!4v1720515364207!5m2!1sen!2skh`
    },
    businessBanner : image,
    productCategory : [
        "Popular",
        "Ice Beverage",
        "Rice",
        "Noodle Soup",
    ]
}

