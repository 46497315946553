import React from "react";

type Props = {
    requiredField: any
}

export const RequiredField: React.FC<Props> = ({requiredField}) => {
    return (
        <div className='invalid-feedback'>
                <span role='alert'>{requiredField}</span>
        </div>
    );
};