import React, {useEffect} from "react";
import thumbnail from "../../../_kiloit/assets/images/landingpage-compressed.png";
import Header from "../../../_kiloit/layout/components/Header";
import {Container} from "react-bootstrap";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {
    changeLayout,
    changeLayoutMode, changeLayoutPosition, changeLayoutWidth,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebar, changeSidebarImageType, changeTopbarTheme
} from "../../../_kiloit/layout/core/action";

const LandingPage = () => {
    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            leftSidebarType: layout.leftSidebarType,
            layoutModeType: layout.layoutModeType,
            layoutWidthType: layout.layoutWidthType,
            layoutPositionType: layout.layoutPositionType,
            topbarThemeType: layout.topbarThemeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            leftSidebarViewType: layout.leftSidebarViewType,
            leftSidebarImageType: layout.leftSidebarImageType,
        })
    );

    // Inside your component
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
    } = useSelector(selectLayoutProperties);

    /*
      layout settings
      */

    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        ) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebar(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType));
        }
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch,
    ]);

    return (
        <>
            <div>
                <Header/>
                <div className="main-content">
                    <div className="page-content px-0 pb-3">
                        <Container fluid>
                            <div
                                className="min-vh-80 d-flex flex-column flex-lg-row font-family-roboto-flex justify-content-center justify-content-lg-between align-items-center">
                                <div className="col-12 col-lg-6">
                                    <h1 className="col-12 col-lg-10 fw-bolder landing-header-title"
                                        style={{fontSize: "2.8rem"}}>Grow
                                        your
                                        businesses with stunning digital solutions</h1>
                                    <div className="mt-4 font-family-roboto">
                                        <p className="col-lg-7 col-12 landing-text">Generate more profile, more sales
                                            especially help to
                                            manage your business more
                                            effective & efficient than ever. KiloBoard is only
                                            one choice that provide a central of business operation. <span
                                                className="text-main cursor-pointer">Read more...</span></p>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-lg-4 mt-3 d-flex justify-content-center justify-content-lg-end">
                                    <div className="w-100">
                                        <img className="w-100" src={thumbnail} alt=""/>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
};

export default LandingPage;
