import React, { FC, Fragment } from 'react'

type Props = {
    title : string
    subtitle? : string
    description? : string
}

const PageTitle: FC<Props> = ({title ,subtitle , description}) => {
  return (
    <Fragment>
        <div className='text-dark-main'>
            <h3 className='fw-bold mb-3 text-dark-main'>{title}</h3>
            {subtitle && <h5 className='fw-bold mb-0 text-dark-main'>{subtitle}</h5>}
            <p >{description}</p>
        </div>
    </Fragment>
  )
}

export default PageTitle