import React, {FC, ReactNode} from "react";
import {Modal} from "react-bootstrap";

interface Props {
    centered?: boolean;
    size?: "sm" | "lg" | "xl";
    dialogClassName?: string;
    contentClassName?: string;
    modalHeader?: ReactNode;
    modalBody?: ReactNode;
    modalFooter?: ReactNode;
    show: boolean;
    onHide: any;
}

const ModalWrapper:FC<Props> = ({
                                    centered = false,
                                    size,
                                    dialogClassName,
                                    contentClassName,
                                    modalHeader,
                                    modalBody,
                                    modalFooter,
                                    show,
                                    onHide}) => {
    return (
        <Modal centered={centered} size={size} show={show} onHide={onHide} dialogClassName={dialogClassName} contentClassName={contentClassName}>
            {modalHeader || null}
            {modalBody || null}
            {modalFooter || null}
        </Modal>
    )
}

export default ModalWrapper