import {combineReducers} from '@reduxjs/toolkit';
import layout from "../../_kiloit/layout/core/reducer";
import settingSlice from "../modules/setting/core/reducer";
import authSlice from "../modules/auth/core/reducer"
import businessSlice from "../modules/business/core/reducer";
import  addProductSlice from "../modules/business/components/product-suite/add-product/core/reducer";

const rootReducer = combineReducers({
    Layout: layout,
    setting: settingSlice,
    auth: authSlice,
    business: businessSlice,
    addProduct: addProductSlice,
})


export default rootReducer
