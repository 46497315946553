import React, {Fragment} from "react";
import MobilePhone from "./components/mobilecontainer/MobilePhone";
import {Col, Container, Image, Row} from "react-bootstrap";
import {business} from "../../core/dummy/dummy";
import ProductCard from "./components/ProductCard";
import {svgUrl} from "../../../../../../../../_kiloit/helpers";
import GoogleMaps from "../../../address-and-banner/components/GoogleMaps";
import CustomCarousel from "../../../../../../widgets/CustomCarousel";
import {useBusiness} from "../../../../../core/action";

const MainMobile = () => {
	const { business: { businessName, address, location, banners } } = useBusiness();

	return (
		<Fragment>
			<Container className="d-flex justify-content-center justify-content-lg-end p-0 overflow-x-scroll no-scrollbar">
				<Row>
					{/* first mobile */}
					<Col>
						<MobilePhone>
							<div>
								<div
									className="z-1 bg-white rounded-circle  position-absolute d-flex justify-content-center"
									style={{
										width: "30px",
										height: "30px",
										top: "7%",
										left: "5%",
									}}
								>
									<img width={15} src={svgUrl("arrow_back_ios.svg")} alt="arrow back" />
								</div>
								<div className="w-100 d-flex justify-content-center">
									{banners.length === 0 ? (
										<CustomCarousel className="rounded mobile" indicators={false} items={[{id: 1, src: svgUrl("banner1.svg")}]} />
									) : (
										<CustomCarousel className="rounded mobile" indicators={false} items={banners.map((banner, index) => {
											return {
												id: index,
												src: banner,
											}
										})} />
									)}
								</div>
								<Container className="mt-1 ">
									<div>
										<Image src={svgUrl("profile-placeholder.svg")} alt="profile" />
										<span className="fw-bold ms-2">
											{businessName}
										</span>
									</div>
									<div className="my-2">
										{
											business.productCategory.map((item, index) => <span className={`me-3 pb-1 text-nowrap ${index === 0 && `text-danger border-bottom border-danger border-3`}`} key={index}>
												{item}
											</span>)
										}
									</div>
									<Row className="mt-2">
										<p className="fw-bold mb-2">Most Ordered</p>
										{Array(6)
											.fill(1)
											.map((item, index) => {
												return <ProductCard key={index} />;
											})}
									</Row>
								</Container>
							</div>
						</MobilePhone>
					</Col>

					{/* second mobile */}

					<Col>
						<MobilePhone>
							<div style={{ height: '100vh' }} className="pt-5"  >
								<div className="border-bottom ps-3 pb-2 d-flex align-items-center">
									<Image className="me-2" src={svgUrl("arrow_back_ios.svg")} alt="arrow_back" />
									<span className="fw-bold">{businessName}</span>
								</div>
								<div className="p-3">
									<Row>
										<Col xs={1}>
											<Image className="mb-1" src={svgUrl("location_on.svg")} />
										</Col>
										<Col>
											<span className="fw-bold text-end">{address}</span>
										</Col>
									</Row>
								</div>
								<GoogleMaps mapTypeControl={false} height="260px" currentLocation={location} />
							</div>
						</MobilePhone>
					</Col>
				</Row>
			</Container>
		</Fragment>
	)
};

export default MainMobile;
