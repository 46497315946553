const categoryOptions = [
    {
        id: 1,
        label: "Restaurant",
        value: "Restaurant",
    },
    {
        id: 2,
        label: "Coffee Shop",
        value: "Coffee Shop",
    },
    {
        id: 3,
        label: "Food & Beverages Stall",
        value: "Food & Beverages Stall",
    },
    {
        id: 4,
        label: "Other",
        value: "Other",
    },
]
const bcAndContactObj = {
    businessName: "HY Chanhan",
    businessCategory: "",
    fullName: "",
    position: "",
    phoneNumber: "",
    email: "",
}
const sliderImages = Array(3).fill("slider1.svg").map((item, index) => (
    {
        id: index + 1,
        src: item,
    }
))
const addressAndBannerObj = {
    businessAddress: "",
    banners: [],
    location: null,
}

export {categoryOptions, bcAndContactObj, sliderImages, addressAndBannerObj}