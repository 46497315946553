import React, {useEffect} from "react";
import {applicationInfo} from "./core/dumb-data/App-Info";
import ProductCard from "./component/ProductCard";
import {Col, Row, Spinner} from "react-bootstrap";
import CustomBtn from "../../../../widgets/CustomBtn";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../../../redux/hooks/hook";
import {useAddProduct} from "./core/actions";
import PageTitle from "../../../../widgets/PageTitle";

const AddProductPage = () => {

    const navigate = useNavigate();
    const {products, selectedProduct} = useAppSelector(state => state.addProduct);
    const {getAllProducts, handleSelectedProduct, loading} = useAddProduct();

    const getCol = (total: number, index: number) => {
        const rest = total % 3;
        switch (rest) {
            case 1:
                if (index === total) return 12;
                break;
            case 2:
                if (index === total || index === total - 1) return 6;
                break;
            default:
                break;
        }
        return 4;
    };

    const getColMd = (total: number, index: number) => {
        return (total % 2 === 1 && index === total) ? 12 : 6;
    };


    useEffect(() => {
        getAllProducts();
        // eslint-disable-next-line
    }, [])

    const handleNext = () => {
        navigate("/product-suite/contacts");
    }

    return (
        <div className="font-family-roboto">
            <PageTitle title="Product Suite" description="Please select the application that you want to access."/>
            <Row>
                {loading ? (
                    <div className="py-5 d-flex justify-content-center align-items-center">
                        <Spinner variant="main"/>
                    </div>
                ) : (
                    <>
                        {products.map((item, index) => {
                            return <Col className="py-2" key={index} md={getColMd(applicationInfo.length, index + 1)}
                                        onClick={() => handleSelectedProduct(item.id, item.activated)}
                                        onDoubleClick={() => handleSelectedProduct(null, false)}
                                        lg={getCol(applicationInfo.length, index + 1)}>
                                <ProductCard
                                    {...item}
                                    disabled={item.activated}
                                    productIndex={selectedProduct}
                                    classProps={applicationInfo[index].classProps}
                                    productImage={applicationInfo[index].image}
                                />
                            </Col>
                        })}
                    </>
                )}
            </Row>
            <footer className="d-flex justify-content-end pt-5 mb-4">
                <div className="col-4 col-md-2">
                    <CustomBtn
                        disable={selectedProduct === null}
                        title="Next"
                        func={handleNext}/>
                </div>
            </footer>
        </div>
    );
};

export default AddProductPage;
