import {createSlice} from "@reduxjs/toolkit";
import {BusinessModel, CategoryModel, initialBusiness, SliderImagesModel} from "./model";
import {sliderImages} from "./dummy/mainDashbaord";

interface ModelState {
    loading: boolean;
    categoryOptions: CategoryModel[];
    sliderImages: SliderImagesModel[];
    business: BusinessModel;
    isCompleted: boolean;
    errorMessage: string | null;
}

const initialState: ModelState = {
    loading: false,
    categoryOptions: [],
    sliderImages: sliderImages,
    business: initialBusiness,
    isCompleted: false,
    errorMessage: null,
}

export const businessSlice = createSlice({
    name: "business",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAppId: (state, action) => {
            state.business.appId = action.payload;
        },
        setCategoryOptions: (state, action) => {
            state.categoryOptions = action.payload;
        },
        setBusiness: (state, action) => {
            state.business = {...state.business, ...action.payload};
        },
        reInitialState: (state, action) => {
            state.categoryOptions = [];
            state.business = initialState.business;
            state.isCompleted = action.payload;
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        }
    }
})

export const {
    setLoading,
    setAppId,
    setCategoryOptions,
    setBusiness,
    reInitialState,
    setErrorMessage
} = businessSlice.actions
export default businessSlice.reducer