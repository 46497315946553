import {instanceApi} from "../../../../_kiloit/helpers/axiosHelper";
import {productSuiteEndpoint} from "./endpoint";
import {BusinessModel} from "./model";

const reqGetBusinessOptions = (id: number) => {
    return instanceApi.get(`${productSuiteEndpoint.businessOptions}?appId=${id}`);
}
const reqCreateMerchant = (payload: BusinessModel) => {
    return instanceApi.post(productSuiteEndpoint.createMerchant, payload);
}

export {reqGetBusinessOptions, reqCreateMerchant}