import Header from "./Header";
import {useEffect} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";

//import actions
import {
    changeLayout,
    changeLayoutMode,
    changeLayoutPosition,
    changeLayoutWidth,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebar,
    changeSidebarImageType,
    changeTopbarTheme,
} from "../core/action";
import {Container} from "react-bootstrap";
import {useAuth} from "../../../app/modules/auth/core/action";
import {getCurrentUser} from "../../../app/modules/auth";

const Layout = () => {
    const {currentUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!getCurrentUser()) {
            navigate("/auth/sign-in");
        }
        // eslint-disable-next-line
    }, [currentUser]);

    const dispatch: any = useDispatch();

    const selectLayoutState = (state: any) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            layoutType: layout.layoutType,
            leftSidebarType: layout.leftSidebarType,
            layoutModeType: layout.layoutModeType,
            layoutWidthType: layout.layoutWidthType,
            layoutPositionType: layout.layoutPositionType,
            topbarThemeType: layout.topbarThemeType,
            leftsidbarSizeType: layout.leftsidbarSizeType,
            leftSidebarViewType: layout.leftSidebarViewType,
            leftSidebarImageType: layout.leftSidebarImageType,
        })
    );

    // Inside your component
    const {
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
    } = useSelector(selectLayoutProperties);

    /*
      layout settings
      */

    useEffect(() => {
        if (
            layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType
        ) {
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebar(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType));
        }
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        dispatch,
    ]);

    return (
        <>
            <div>
                {/*<TopBar/>*/}
                <Header/>
                <div className="main-content">
                    <div className="page-content px-0 pb-3">
                        <Container fluid>
                            <Outlet/>
                        </Container>
                    </div>
                </div>
                {/*<Footer/>*/}
            </div>
        </>
    );
};

export default Layout;
