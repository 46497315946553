import React from "react";
import {components} from "react-select";
import {Image} from "react-bootstrap";
import {svgUrl} from "../../../_kiloit/helpers";

const CustomReactSelect = (props: any) => {
    const {selectProps} = props;
    const {menuIsOpen} = selectProps;

    return (
        <div
            style={{
                transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s'
            }}
        >
            <components.DropdownIndicator {...props} />
        </div>
    )
}

const CustomMultiValue =(props: any) => {
    return (
        <components.MultiValue {...props} className="rounded-pill px-1">
            {props.children}
        </components.MultiValue>
    )
}

const CustomMultiValueRemove = (props: any) => {
    return (
        <components.MultiValueRemove {...props}>
            <Image className="w-100" src={svgUrl("remove.svg")} />
        </components.MultiValueRemove>
    )
}

export {CustomReactSelect, CustomMultiValue, CustomMultiValueRemove}