const AttractionSVG = ({color}) => (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.1436 21.2335L21.229 19.1481L30.6206 28.5441L28.5396 30.6251L19.1436 21.2335ZM25.4042 12.8772L29.575 8.7064C23.8146 2.94598 14.4813 2.9314 8.72086 8.67723C14.4521 6.7814 20.8396 8.31265 25.4042 12.8772ZM8.67711 8.72098C2.93127 14.4814 2.94586 23.8147 8.70627 29.5751L12.8771 25.4043C8.31252 20.8397 6.78127 14.4522 8.67711 8.72098ZM8.70627 8.69181L8.69169 8.7064C8.13752 13.096 10.3979 18.7397 14.9625 23.3189L23.3188 14.9626C18.7542 10.3981 13.0959 8.13765 8.70627 8.69181Z"
            fill={color}
            fillOpacity={0.87}
        />
    </svg>
)

export default AttractionSVG;