import React, {FC} from "react";
import {Image} from "react-bootstrap";
import {authRedirectUrl} from "../../../constants/redirect";

interface Props {
    id?: number;
    name: string;
    icon: string;
}

const AppCard: FC<Props> = ({id, name, icon}) => {

    return (
        <React.Fragment>
            <div key={id} className="cursor-pointer text-center py-4 px-2 rounded-4 app-board-wrapper"
                 onClick={() => window.location.href = `${authRedirectUrl.toProduct}`}>
                <div className="app-board-card d-block mx-auto">
                    <Image className="w-100" src={icon}/>
                </div>
                <p className="mb-0 mt-2 text-truncate">{name}</p>
            </div>
        </React.Fragment>
    )
}

export default AppCard