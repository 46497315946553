const useHelper = () => {
    const getCroppedImage = (
        imageSrc: string,
        pixelCrop: { x: number; y: number; width: number; height: number },
        targetWidth: number = 360,
        targetHeight: number = 235
    ): Promise<File> => {
        // Create an image element from the source URL
        const createImage = (url: string): Promise<HTMLImageElement> =>
            new Promise((resolve, reject) => {
                const image = new Image();
                image.addEventListener("load", () => resolve(image));
                image.addEventListener("error", (error) => reject(error));
                image.src = url;
            });

        return createImage(imageSrc).then((image) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // Calculate aspect ratios
            const aspectRatio = pixelCrop.width / pixelCrop.height;
            const targetAspectRatio = targetWidth / targetHeight;

            // Determine the drawing dimensions and offsets
            let drawWidth = targetWidth;
            let drawHeight = targetHeight;
            let offsetX = 0;
            let offsetY = 0;

            if (aspectRatio > targetAspectRatio) {
                // Crop is wider than target: adjust width
                drawWidth = targetHeight * aspectRatio;
                offsetX = -(drawWidth - targetWidth) / 2;
            } else {
                // Crop is taller than target: adjust height
                drawHeight = targetWidth / aspectRatio;
                offsetY = -(drawHeight - targetHeight) / 2;
            }

            // Set canvas size to the target output size
            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // Draw the cropped image onto the canvas with proper aspect ratio
            if (ctx) {
                ctx.drawImage(
                    image,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    offsetX,
                    offsetY,
                    drawWidth,
                    drawHeight
                );
            } else {
                throw new Error("Failed to get canvas context");
            }

            // Return the resized cropped image as a File object
            return new Promise<File>((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (blob) {
                        const file = new File([blob], "cropped-image.png", { type: "image/png" });
                        resolve(file);
                    } else {
                        reject(new Error("Blob creation failed"));
                    }
                }, "image/png");
            });
        });
    };

    return {getCroppedImage}
}

export  {useHelper}