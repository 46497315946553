import React, {FC, useState} from "react";
import ModalWrapper from "../../../../../widgets/ModalWrapper";
import CustomCropper from "../../../../../widgets/CustomCropper";
import {Button, Col, Modal, Row} from "react-bootstrap";
import {useBusiness} from "../../../../core/action";

interface Props {
    show: boolean;
    onHide: any;
    image: string;
    formik: any;
}

const CropperModal:FC<Props> = ({show, onHide, image, formik}) => {
    const [file, setFile] = useState<File | null>(null);
    const {business, uploadBanner} = useBusiness();
    const handleHide = () => {
        onHide(false);
    }
    const handleUploadBanner = () => {
        onHide(false);
        if (file) {
            const formData = new FormData();
            // @ts-ignore
            formData.append("files", file);
            return uploadBanner(formData, business.appId || 1, formik, "banners", 1500);
        }
    }

    return (
        <React.Fragment>
            <ModalWrapper
                show={show}
                onHide={handleHide}
                modalHeader={<Modal.Header closeButton>
                    <Modal.Title className="text-black">Crop and resize banner</Modal.Title>
                </Modal.Header>}
                modalBody={<Modal.Body className="overflow-hidden m-3">
                    <div>
                        <CustomCropper image={image} setFile={setFile} />
                    </div>
                </Modal.Body>}
                modalFooter={
                    <Modal.Footer>
                        <Row className="w-100 justify-content-end g-0">
                            <Col xs={6}>
                                <Row className="g-2">
                                    <Col>
                                        <Button onClick={handleHide} className="d-block w-100" variant="light-danger">Cancel</Button>
                                    </Col>
                                    <Col>
                                        <Button onClick={handleUploadBanner} className="d-block w-100" variant="main">Done</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Footer>
                }
                size="lg"
                centered
                contentClassName="rounded-4 vh-70 bg-white"
            />
        </React.Fragment>
    )
}

export default CropperModal