import React, {useState} from "react";
import Image from "react-bootstrap/Image";
import {Col, Dropdown, Row} from "react-bootstrap";
//import images
import {useNavigate} from "react-router-dom";
import {svgUrl} from "src/_kiloit/helpers";
import {useAuth} from "../../../app/modules/auth/core/action";
import {getAuth, getMerchant, UserModel} from "../../../app/modules/auth";
import {useAddProduct} from "../../../app/modules/business/components/product-suite/add-product/core/actions";

const ProfileDropdown = () => {
    const navigate = useNavigate()
    const {logout, auth: {user}, allMerchants, switchMerchant} = useAuth();
    const {getAllProducts} = useAddProduct();
    const auth = getAuth();
    const merchant = getMerchant() ?? user;
    // Inside your component


    const handleLogout = () => {
        if (auth?.accessToken)
            logout(auth?.accessToken);
        navigate("/");
    };

    const [sections, setSections] = useState<String>("")

    const sectionSwitch = (section: string) => {
        setSections(section)
    };

    const dummy2 = [
        {
            name: "Luna Prime Coffe",
            status: true,
            isInvited: false
        },
        {
            name: "Luna Prime Hotel",
            status: false,
            isInvited: false
        },
        {
            name: "Luna Prime Tour",
            status: false,
            isInvited: false
        }, {
            name: "Luna Prime Coffe",
            status: false,
            isInvited: true
        }, {
            name: "Starbuck AEON2",
            status: false,
            isInvited: true
        }, {
            name: "Tube Coffe",
            status: false,
            isInvited: true
        }, {
            name: "Pizza Company",
            status: false,
            isInvited: true
        }
    ]

    const handleSwitchMerchant = (payload: UserModel) => {
        switchMerchant(payload).then(() => {
            getAllProducts();
        });
    }

    const merchantId = getMerchant()?.id ?? user?.id;

    return (
        <React.Fragment>
            <Dropdown autoClose={sections !== "" ? undefined : "outside"}
                      className=" ms-2 header-item topbar-user bg-transparent">
                <span className="d-flex align-items-center">
                    <span className="mt-2">
                        <span className="d-none d-xl-inline-block ms-1 fs-16"
                              style={{color: "#333333", lineHeight: "10px"}}>
                            Hello, {merchant?.name}
                        </span>
                        <span className="d-none d-xl-block ms-1 text-end fs-12"
                              style={{color: "#C4C4C4", lineHeight: "10px"}}>
                            {/*{user?.accountType}*/}
                            Owner
                        </span>
                    </span>
                </span>
                <Dropdown.Toggle
                    type="button"
                    className="btn bg-transparent border-0 arrow-none"
                    id="page-header-user-dropdown dropdown-autoclose-outside">

                    <Image
                        className="rounded-circle header-profile-user"
                        src={merchant?.logo}
                        alt="Header Avatar"
                    />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-end custom-dropdown-menu profile-dropdown">
                    {
                        !sections ? <>
                                <Dropdown.Item onClick={() => sectionSwitch("profile")}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("my-profile.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">My Profile</span>
                                            <p className="custom-text-muted arrow-to-right fw-light fs-12 pb-1">View your
                                                profile</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                                {/*<Dropdown.Item onClick={() => sectionSwitch("connections")}>*/}
                                {/*    <Row>*/}
                                {/*        <Col xs={2}><img className="icon-size align-middle" src={svgUrl("connections.svg")}*/}
                                {/*                         alt=""/></Col>*/}
                                {/*        <Col xs={10} className="px-3">*/}
                                {/*            <span className="align-middle">Your Connections</span>*/}
                                {/*            <p className="custom-text-muted arrow-to-right fw-light fs-12 ">Swtich*/}
                                {/*                Connections</p>*/}
                                {/*        </Col>*/}
                                {/*    </Row>*/}
                                {/*</Dropdown.Item>*/}
                                <Dropdown.Item onClick={() => navigate("/")}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("setting.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">Settings</span>
                                            <p className="custom-text-muted arrow-to-right fw-light fs-12 pb-1">Update
                                                info,subscription...</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleLogout}>
                                    <Row>
                                        <Col xs={2}><img className="icon-size" src={svgUrl("sign-out.svg")} alt=""/></Col>
                                        <Col xs={10} className="px-3">
                                            <span className="align-middle">Sign out</span>
                                            <p className="custom-text-muted  arrow-to-right fw-light fs-12 pb-1">Exit the
                                                system</p>
                                        </Col>
                                    </Row>
                                </Dropdown.Item>
                            </>
                            :
                            <>
                                {sections === "profile" ?
                                    <>
                                        <div style={{borderBottom: "2px dashed #ccc"}}>
                                            <span onClick={() => sectionSwitch("")}
                                                  className="fw-semibold   cursor-pointer ps-3">
                                                <img width={11} height={11} src={svgUrl("arrow-left.svg")} alt=""/>
                                                <span className="ms-2">Back</span>
                                            </span>
                                            <p className="ms-3 my-2 ">My Profile</p>
                                        </div>

                                        <p style={{color: "#005D7B"}}
                                           className="fs-16 mt-2 fw-bold ms-2 ps-2 mb-1">Switch Business</p>
                                        {
                                            dummy2.map((item, index) => (
                                                <Dropdown.Item onClick={() => sectionSwitch("")}
                                                               className="border-0 pe-1 mb-0" key={index}>
                                                    <span
                                                        className={`${item.status && "fw-bold"}`}>{index + 1}. {" "} {item.name}</span>
                                                    {item.status &&
                                                        <img width={13} src={svgUrl("check-circle.svg")} alt=""/>}
                                                    <span style={{color: "#0BBCD4"}}
                                                          className="fs-12 ms-2">{item.isInvited && "(Invited)"}</span>
                                                </Dropdown.Item>))
                                        }
                                    </>
                                    : (
                                        <>
                                            <p onClick={() => sectionSwitch("")}
                                               className="fw-semibold ms-3 cursor-pointer ps-1">
                                                <img width={11} height={11} src={svgUrl("arrow-left.svg")} alt=""/>
                                                <span className="ms-2">Back</span>
                                            </p>
                                            {allMerchants.map((item, index) => {
                                                return <Dropdown.Item className="pe-4" key={index}
                                                                      onClick={() => handleSwitchMerchant(item)}>
                                                    <Row>
                                                        <Col xs={2}>
                                                            <img className="rounded-circle" width={20} src={item.logo}
                                                                 alt=""/>
                                                        </Col>
                                                        <Col xs={10} className="ps-3">
                                                            <span
                                                                className={`${item.name.length > 25 ? "fs-10 pe-3 me-1" : ""}`}>
                                                                {item.name}
                                                            </span>
                                                            <span className="ms-3 fs-12" style={{color: "#1ABCFE"}}>
                                                                {item.id === Number(merchantId) ? "(active)" : null}
                                                            </span>
                                                            <p className="custom-text-muted fw-light fs-10 pb-1">0{item.phoneNumber}</p>
                                                        </Col>
                                                    </Row>
                                                </Dropdown.Item>

                                            })}
                                        </>
                                    )}
                            </>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;