export const authEndpoint = {
    // profile
    getUserByAccessToken: "/merchant/api/v1/merchant",
    getAllMerchants: "/merchant/api/v1/merchant/merchants",
    switchMerchant: "/merchant/api/v1/merchant/logs",

    // authentication
    exchangeAccessToken: "/oauth2/token",

    // logout
    logout: "/logout"
}