import React from "react";
import {Route, Routes} from "react-router-dom";
import Layout from "../../_kiloit/layout/components/Layout";
import NotFoundPage from "../modules/errors/NotFoundPage";

//Modules Page

import ProductSuitePage from "../modules/business/components/product-suite/ProductSuitePage";
import AppBoard from "../modules/landingpage/AppBoard";


const PrivateRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="product-suite/*" element={<ProductSuitePage/>}/>
                <Route path="help" element={<h2>Help</h2>}/>
                <Route index element={<AppBoard />} />
            </Route>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
    );
};

export {PrivateRoutes};
