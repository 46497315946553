import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import StepProgressBar from "../../../../widgets/StepProgressBar";
import InputForm from "../../../../widgets/InputForm";
import {FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import MultipleFileUpload from "../../../../widgets/MultipleFileUpload";
import GoogleMaps from "./components/GoogleMaps";
import CustomBtn from "../../../../widgets/CustomBtn";
import PageTitle from "../../../../widgets/PageTitle";
import {useBusiness} from "../../../core/action";
import MapModal from "./components/MapModal";
import {LocationModel} from "../../../core/model";
import CropperModal from "./components/CropperModal";

const validationSchema = Yup.object({
    address: Yup.string().required("Business Address is required!"),
});

const AddressAndBannerPage = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showCropper, setShowCropper] = useState<boolean>(false);
    const [image, setImage] = useState<string>("");
    const navigate = useNavigate();
    const { business, handleSetBusiness } = useBusiness();
    const [objForEdit] = useState({
        ...business,
    });
    const formik = useFormik({
        initialValues: objForEdit,
        validationSchema: validationSchema,
        validateOnMount: true,
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values);
            navigate("/product-suite/review");
        }
    });
    const handleConfirmLocation = (location: LocationModel, businessAddress: string) => {
        formik.setFieldValue("address", businessAddress);
        formik.setFieldValue("location", location);
        handleSetBusiness({ address: businessAddress, location });
    }
    const handleUploadBanner = (files: File[]) => {
        const imagePreview = URL.createObjectURL(files[0]);
        setImage(imagePreview);
        setShowCropper(true);
    }

    useEffect(() => {
        if (business.location) {
            formik.validateForm();
        }
        // eslint-disable-next-line
    }, [business.location]);

    return (
        <React.Fragment>
            <Row xs={1} lg={2} className="mt-3 justify-content-between align-items-start">
                <Col lg={5} className="order-2 order-lg-1">
                    <div className="mt-5 mt-lg-0">
                        <Form onSubmit={formik.handleSubmit}>
                            <PageTitle title="Address and Upload Banner" description="Enter business address, upload the business logo. You must provide address details so that customers will be easier to find it." />
                            <div className="mt-5">
                                <InputForm
                                    className="business"
                                    placeholder=""
                                    required={true}
                                    disabled={false}
                                    type="text"
                                    hasIcon={true}
                                    iconPath="map.png"
                                    iconLabel="Open Map"
                                    iconOnClick={() => setShowModal(true)}
                                    iconClassName="text-main cursor-pointer"
                                    modalWrapper={
                                        <FormikProvider value={formik}>
                                            <MapModal
                                                show={showModal}
                                                onHide={() => setShowModal(false)}
                                                currentLocation={formik.values.location}
                                                onConfirmLocation={handleConfirmLocation}
                                            />
                                        </FormikProvider>
                                    }
                                    name="address"
                                    label="Business Address"
                                    formik={formik}
                                />
                                <Form.Group controlId="uploadBanners" className="mt-3 business">
                                    <Form.Label>Business Banners</Form.Label>
                                    <MultipleFileUpload name="banners" formik={formik} callback={handleUploadBanner} />
                                </Form.Group>
                            </div>
                        </Form>
                        <Row className="g-3 my-4">
                            <Col>
                                <CustomBtn func={() => navigate("/product-suite/contacts")} btntype="back" />
                            </Col>
                            <Col>
                                <CustomBtn disable={!formik.isValid} type="submit" func={formik.handleSubmit} title="Next" />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={5} xl={4} className="order-1 order-lg-2">
                    <div className="me-4 me-lg-0">
                        <StepProgressBar />
                    </div>
                    <div className="rounded-4 shadow-lg overflow-hidden ms-auto me-auto me-lg-0 max-width-obj">
                        <FormikProvider value={formik}>
                            <GoogleMaps mapTypeControl={false} currentLocation={formik.values.location} />
                        </FormikProvider>
                    </div>
                </Col>
            </Row>
            <CropperModal show={showCropper} onHide={setShowCropper} image={image} formik={formik} />
        </React.Fragment>
    )
}

export default AddressAndBannerPage