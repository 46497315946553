import axios from "axios";
import {getAuth, getCurrentUser, getMerchant} from "../../app/modules/auth";

const authApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_URL
});
const instanceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const configInterceptor = (axios: any) => {
    axios.interceptors.request.use((config: any) => {
        const user = getCurrentUser();
        let merchantId = getMerchant()?.id || user?.id;
        if (config.url === "/merchant/api/v1/merchant") {
            merchantId = user?.id;
        }
        config.headers = {
            ...config.headers,
            "Authorization": `Bearer ${getAuth()?.accessToken}`,
        }

        if (merchantId) {
            config.headers["X-Merchant-Id"] = merchantId;
        }
        return config;
    }, (err: any) => {
        return Promise.reject(err);
    });

    axios.interceptors.response.use((res: any) => {
        return res;
    }, (err: any) => {
        return Promise.reject(err);
    });
}

const setUpAxios = () => {
    configInterceptor(instanceApi);
}

export {authApi, instanceApi, setUpAxios}