import {createSlice} from "@reduxjs/toolkit";
import {AuthModel, UserModel} from "./_models";
import {getAuth} from "./AuthHelpers";

interface StateModel {
    auth:AuthModel
    loading: boolean;
    allMerchants: UserModel[];
    message: string;
}

const initialState: StateModel = {
    auth:{
        accessToken: getAuth()?.accessToken ??null,
        user: getAuth()?.user,
    },
    loading: false,
    allMerchants: [],
    message: "",
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setAuth: (state, action) => {
            state.auth = {...state.auth, ...action.payload};
        },
        setAllMerchants: (state, action) => {
            state.allMerchants = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setMessage: (state, action) => {
            state.message = action.payload;
        }
    }
});

export const {setAuth, setAllMerchants, setLoading, setMessage} = authSlice.actions;
export default authSlice.reducer;