import {useDispatch} from "react-redux";
import {setInstalledProduct, setLoading, setProducts, setSelectedProduct} from "./reducer";
import {reqGetAllInstalledApps, reqGetBusiness} from "./request";
import {useAppSelector} from "../../../../../../redux/hooks/hook";
import {useBusiness} from "../../../../core/action";
import {reInitialState} from "../../../../core/reducer";
import {useSweetAlert} from "../../../../../../../_kiloit/helpers/alert/alert";
import {authRedirectUrl} from "../../../../../../constants/redirect";

export const useAddProduct = () => {
    const {alertConfirm} = useSweetAlert();
    const dispatch = useDispatch();
    const {selectedProduct, installedProduct, loading} = useAppSelector(state => state.addProduct);
    const {getBusinessOptions} = useBusiness();

    const getAllProducts = () => {
        dispatch(setLoading(true));
        return reqGetBusiness().then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setProducts(res.data.data));
        }).catch((err: any) => {
            dispatch(setLoading(false));
            console.log(err);
        });
    }

    const getAllInstallApp = () => {
        dispatch(setLoading(true));
        reqGetAllInstalledApps().then((res: any) => {
            dispatch(setLoading(false));
            dispatch(setInstalledProduct(res.data.data));
        }).catch((err: any) => {
            dispatch(setLoading(false));
            console.log(err);
        });
    }

    const handleSelectedProduct = (id: number | null, isInstalled: boolean) => {
        if (id === null) {
            return dispatch(setSelectedProduct(null));
        }
        dispatch(setSelectedProduct(id));
        if (isInstalled) {
            alertConfirm({
                titleConfirm: "The product is already installed",
                textConfirm: "Do you want to open it?",
                preConfirm: () => window.location.href = `${authRedirectUrl.toProduct}`,
                onCancel: () => dispatch(setSelectedProduct(null))
            });
        } else {
            if (selectedProduct !== id)
                dispatch(reInitialState(false));
            return getBusinessOptions(id);
        }
    }

    return {
        selectedProduct,
        installedProduct,
        loading,
        getAllInstallApp,
        getAllProducts,
        handleSelectedProduct,
    }
}