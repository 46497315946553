import React, {Fragment} from 'react'
import PageTitle from '../../../../../../widgets/PageTitle'
import {Col, Image, Row} from 'react-bootstrap'
import {CategoryModel} from "../../../../../core/model";
import {useBusiness} from "../../../../../core/action";

const BusinessInfo = () => {
    const {business} = useBusiness();
    const {businessName, businessCategories, contactName, position, phoneNumber, email, address, location, banners} = business;

    return (
        <Fragment>
            <div className=' px-md-5 px-lg-0'>
            <div className='mb-5'>
                <PageTitle title="Review" subtitle='' description='Please double check all information to make sure that it’s correct before submitting. Your business information will be shown on mobile application as below:' />
            </div>
            {/* first card */}
            <div className='rounded-4 p-3 mb-4 border '>
                <Row className='mb-3'>
                    <Col xs={5}>
                        <p className='custom-text-muted'>Business Name</p>
                    </Col>
                    <Col xs={7}>
                        <p>{businessName}</p>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col xs={5}>
                        <p className='custom-text-muted'>Business Category</p>
                    </Col>
                    <Col xs={7}>
                        <p>{businessCategories.map((category: CategoryModel) => {
                            return category.label;
                        }).join(", ")}</p>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col xs={5}>
                        <p className='custom-text-muted'>Contact Person</p>
                    </Col>
                    <Col xs={7} className="compact-paragraphs">
                        <span>
                            {contactName}
                        </span>
                        <p className='custom-text-muted'>
                            {position} <br />
                            Phone: {phoneNumber} <br />
                            {email === "" ? null : `Email: ${email}`}
                        </p>
                    </Col>
                </Row>
            </div>
            {/* second card */}
            <div className='rounded-4 p-3 mb-3 border overflow-x-scroll no-scrollbar'>
                <Row className='mb-3'>
                    <Col xs={5}>
                        <p className='custom-text-muted'>Business Address</p>
                    </Col>
                    <Col xs={7}>
                        <p>
                            {address}{" "}
                            <a style={{color:"#4085AD"}} className='text-nowrap ' href={`https://www.google.com/maps?q=${location?.lat},${location?.lng}`} target="_blank" rel="noopener noreferrer">(View Map)</a>
                        </p>
                    </Col>
                </Row>
                <Row xs={1} className="g-3">
                    <Col>
                        {banners.length === 0 ? null : (
                            <>
                                <p className='custom-text-muted'>Business Banner</p>
                                <div className="d-flex flex-column gap-3 justify-content-center align-items-start">
                                    {banners.map((banner, index) => (
                                        <Image key={index} className="preview-img" src={banner} />
                                    ))}
                                </div>
                            </>
                        )}
                    </Col>
                </Row>
            </div>
            </div>

        </Fragment>
    )
}

export default BusinessInfo
