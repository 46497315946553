import {DummyApplicationInfo, InstalledAppModel} from "../Model";
import {imageURL, svgUrl} from "../../../../../../../../_kiloit/helpers";


const gradientWaveDefault = "gradient-wave-default";
const gradientWaveFood = "gradient-wave-food";
const gradientWaveStay = "gradient-wave-stay";

export const applicationInfo: Array<DummyApplicationInfo> = [
    {
        id: 0,
        title: "Food Application",
        free: true,
        description: "Sell chicken rice, pork rice, khmer noodles, Samlor korkor, Amok, Prahok ktis, Sour fish soup, Stuffed frogs, Chrok krao chhnang and all kinds of vegetables. coconut juice, orange juice, dessert and more..",
        image: imageURL("add-product/food-app-card.svg"),
        classProps: gradientWaveFood,
        subCategories: [
            {
                id: 1,
                label: "Restaurant",
                value: "Restaurant",
            },
            {
                id: 2,
                label: "Coffee Shop",
                value: "Coffee Shop",
            },
            {
                id: 3,
                label: "Food & Beverages Stall",
                value: "Food & Beverages Stall",
            },
            {
                id: 4,
                label: "Other",
                value: "Other",
            },
        ],
    },
    {
        id: 1,
        title: "Stays Application",
        free: false,
        description: "Search for rental house, hotel, resorts, penthouse, condominium and bungalow for 25 provinces and cities to stay for holidays with friends and families. Guests can find you easily and faster on ‘Stays’ application.",
        image: imageURL("add-product/stay-app-card.svg"),
        classProps: gradientWaveStay,
        subCategories: [
            {
                id: 1,
                label: "Hotel",
                value: "Hotel",
            },
            {
                id: 2,
                label: "Resort",
                value: "Resort",
            },
            {
                id: 3,
                label: "Bungalow",
                value: "Bungalow",
            },
            {
                id: 4,
                label: "Transportation",
                value: "Transportation",
            },
            {
                id: 5,
                label: "Other",
                value: "Other",
            },
        ],
    },
    {
        id: 2,
        title: "Mall Application",
        free: false,
        description: "Sell clothes, jeans, shoes, trousers, hat, glasses, sport clothes, watches, wallets, skirts, cosmetic products, motorbikes, bikes, cars, books, phones, furnitures, home appliances, computers, electronics, and other accessories.",
        image: imageURL("add-product/mall-app-card.svg"),
        classProps: gradientWaveDefault,
        subCategories: [],
    },
    {
        id: 3,
        title: "Attraction Application",
        free: false,
        description: "",
        image: imageURL("add-product/attraction-app-card.svg"),
        classProps: gradientWaveDefault,
        subCategories: [],
    },
    {
        id: 4,
        title: "Car Rental Application",
        free: false,
        description: "Sell clothes, jeans, shoes, trousers, hat, glasses, sport clothes, watches, wallets, skirts, cosmetic products, motorbikes, bikes, cars, books, phones, furnitures, home appliances, computers, electronics, and other accessories.",
        image: imageURL("add-product/car-app-card.svg"),
        classProps: gradientWaveDefault,
        subCategories: [],
    },
    {
        id: 5,
        title: "Transportation Application",
        free: false,
        description: "",
        image: imageURL("add-product/transport-app-card.svg"),
        classProps: gradientWaveDefault,
        subCategories: [],
    }
];

export const InstallApp: Array<InstalledAppModel> = [
    {
        id: 0,
        name: 'KiloFOOD',
        icon: svgUrl("icons-for-hamburger/restaurant-app-icon.svg")
    },
    {
        id: 1,
        name: 'KiloSTAY',
        icon: svgUrl("icons-for-hamburger/hotel.svg")
    },
    {
        id: 2,
        name: 'KiloATTR    ',
        icon: svgUrl("icons-for-hamburger/attraction.svg")
    }
]