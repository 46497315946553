import React, {FC} from 'react';
import {ApplicationInfo} from "../core/Model";
import FreeTag from "./FreeProductTag";
import PayProductTag from "./PayProductTag";
import HelpBtn from "./Help";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";

interface DashboardCardProps extends ApplicationInfo {
    productIndex?: number | null;
    productImage? : string | null;
    activated: boolean;
    disabled?: boolean;
}

const ProductCard: FC<DashboardCardProps> = ({id, name, description, thumbnail, usage, productIndex, classProps, productImage, activated, disabled}) => {
    const isSelected = id === productIndex;
    const navi = useNavigate();

    return (
        <div
            className={clsx("position-relative product-card cursor-pointer rounded-4 d-flex p-1 pt-0 px-0 flex-column")}
            style={{
                backgroundImage: `url(${productImage})`,
                backgroundRepeat: "repeat",
                backgroundSize: "cover",
                height: "100%",
                border: `2px solid ${isSelected ? '#0BBCD4' : 'white'}`,
            }}
        >
            <div className={`${classProps}`}></div>
            {/* Add this div */}
            <header className="d-flex align-items-center">
                <div className="position-relative ps-3 pt-3 text-white mb-2" style={{width: 'fit-content'}}>
                    <h1 className="fs-4 fs-md-3 fs-lg-2 fs-xl-1 fw-bold text-truncate pb-0 mb-0"
                        style={{width: 'fit-content'}}>
                        {name}
                    </h1>
                    {usage === "FREE" ? <FreeTag/> : <PayProductTag/>}
                </div>
                {activated && <div className="position-absolute top-0 end-0">
                    <div className="installation-status bg-black text-white text-center">Installed</div>
                </div>}
            </header>
            <section className="ps-3 pe-5 pb-4 mb-2 pt-0 mt-0 position-relative">
                <p style={{fontSize: "12px"}}>{description}</p>
            </section>
            <footer className="position-absolute bottom-0 ms-3 pb-3 mt-0">
                <HelpBtn
                    onClick={() => {navi("/help")}}
                />
            </footer>
        </div>
    );
};

export default ProductCard;
