import React, {FC, useCallback} from "react";
import {Image} from "react-bootstrap";
import {svgUrl} from "../../../_kiloit/helpers";
import {useDropzone} from "react-dropzone";
import {useBusiness} from "../business/core/action";
import LoadingProgress from "./LoadingProgress";

interface Props {
    name: string;
    formik?: any;
    callback?: any;
}

const MultipleFileUpload: FC<Props> = ({name, formik, callback}) => {
    const {business, deleteBanner, loading, errorMessage, handleSetErrorMessage} = useBusiness();
    const values = formik.values[name];
    const onDrop = useCallback((acceptedFiles: File[]) => {
            if (acceptedFiles[0].size <= 2 * 1024 * 1024) {
                if (values.length + acceptedFiles.length <= 3) {
                    handleSetErrorMessage(null);
                    callback && callback(acceptedFiles)
                } else {
                    handleSetErrorMessage("Sorry, you can upload only 3 banners.");
                }
            } else {
                handleSetErrorMessage("The file size exceeds maximum allowed 2 MB.");
            }
        },
        // eslint-disable-next-line
        [values, callback]);
    const {getRootProps, getInputProps, open} = useDropzone({
        accept: {
            "image/jpg": [],
            "image/jpeg": [],
            "image/png": [],
        },
        onDrop,
        multiple: false,
        maxFiles: 3
    });
    const handleClick = (e: any) => {
        if (values.length >= 3) {
            e.preventDefault();
            handleSetErrorMessage("Sorry, you can upload only 3 banners.");
        } else {
            handleSetErrorMessage(null);
            if (!loading)
                open();
        }
    }

    return (
        <React.Fragment>
            <div {...getRootProps()} onClick={handleClick}
                 style={{minHeight: "120px"}}
                 className="dropzone cursor-pointer align-content-center rounded-4 p-2 px-lg-5 text-center">
                {loading ? <LoadingProgress title="Uploading in progress" loading={loading}/> : <>
                    <input {...getInputProps()} />
                    <Image className="dz-image mt-1 mb-3" src={svgUrl("drag.svg")}/>
                    <div className="w-100 d-block mx-auto">
                        <p className="custom-text-muted mb-0">Simply drag and drop banners, or click to select and upload from your device.
                            Allowed maximum 3 banners and file size 2MB only.
                        </p>
                    </div>
                </>}
            </div>
            <p className="mt-1 custom-light-text-muted">Accepted file type: '*.jpg, *.jpeg, *.png. Banner size (W x H):
                360 *
                235 pixels.</p>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            {values.length >= 1 && values.length <= 3 && <p className="custom-text-muted">Uploaded</p>}
            <div className="overflow-x-scroll no-scrollbar">
                {business.banners.map((banner, index) => (
                    <div key={index} className="banner-preview w-100">
                        <div
                            className="d-flex justify-content-between align-items-center gap-2 mb-3">
                            <Image className="preview-img" src={banner}/>
                            <span>
                            <Image onClick={() => deleteBanner(banner, formik)} className="cursor-pointer"
                                   src={svgUrl("delete.svg")}/>
                        </span>
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

export default MultipleFileUpload;