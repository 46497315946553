import {FC, useEffect, useRef, useState} from 'react'
import {useAuth} from "../core/action";

const AuthInit: FC<any> = ({children}) => {
    const {
        auth: {accessToken}, logout,
        setCurrentUser,
        saveAuth,
        getUserByAccessToken
    } = useAuth();
    const didRequest = useRef(false);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
    useEffect(() => {
        const requestUser = () => {
            try {
                if (!didRequest.current) {
                    getUserByAccessToken();
                }
            } catch (error) {
                console.error(error)
                if (!didRequest.current) {
                    if (accessToken)
                        logout(accessToken);
                }
            } finally {
                setShowSplashScreen(false);
            }

            return () => (didRequest.current = true);
        }
        if (accessToken) {
            requestUser();
        } else {
            saveAuth(undefined);
            setCurrentUser(undefined);
            setShowSplashScreen(false);
        }
        // eslint-disable-next-line
    }, [accessToken]);

    return showSplashScreen ? <>{children}</> : <>{children}</>
}

export {AuthInit}
