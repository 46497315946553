import {instanceApi} from "../../../../../../../_kiloit/helpers/axiosHelper";
import {productSuiteEndpoint} from "../../../../core/endpoint";

const reqGetBusiness = () => {
    return instanceApi.get(productSuiteEndpoint.app);
}
const reqGetAllInstalledApps = () => {
    return instanceApi.get(productSuiteEndpoint.createMerchant);
}

export {reqGetBusiness, reqGetAllInstalledApps}