import React, {FC} from "react";
import {Form} from "react-bootstrap";
import clsx from "clsx";
import Select from "react-select";
import {RequiredField} from "./RequiredField";
import {CustomReactSelect, CustomMultiValue, CustomMultiValueRemove} from "./CustomReactSelect";

interface Props {
    className?: string;
    value?: any;
    onChange?: any;
    placeholder?: string;
    label?: string;
    options?: any;
    required?: boolean;
    isDisabled?: boolean;
    name: string;
    isMulti: boolean;
    formik: any;
    max?: number;
}

const SelectForm:FC<Props> = ({
                                  className,
                                  value,
                                  onChange,
                                  placeholder,
                                  label,
                                  options,
                                  required = false,
                                  isDisabled = false,
                                  name,
                                  isMulti,
                                  formik,
                                  max = options.length
                              }) => {

    const formikTouched = formik.touched[name];
    const formikErrors = formik.errors[name];
    const isInValid = formikTouched && formikErrors;
    const getValue = options.find((e: any) => e.value === value) ?? null;

    return (
        <Form.Group controlId={name} className={`mt-3 ${className}`}>
            <Form.Label className={clsx({"required": required})}>{label}</Form.Label>
            <Select
                components={{
                    ClearIndicator: false,
                    IndicatorSeparator: false,
                    DropdownIndicator: CustomReactSelect,
                    MultiValue: CustomMultiValue,
                    MultiValueRemove: CustomMultiValueRemove
            }}
                isOptionDisabled={() => value.length >= max}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        padding: "2.47px",
                        borderColor: formik.touched[name] && formik.errors[name] ? "#DC3545FF" : "#E9EAEF",
                        "&:hover": {
                            borderColor: formik.touched[name] && formik.errors[name] ? "#DC3545FF" : "#E9EAEF",
                        },
                    }),
                    multiValueRemove: (baseStyles) => ({
                        ...baseStyles,
                        "&:hover": {
                            backgroundColor: "transparent",
                        },
                    })
                }}
                className={clsx("form-select p-0 border-0", {"is-invalid is-invalid-select" : isInValid})}
                {...formik.getFieldProps(name)}
                placeholder={placeholder}
                options={options}
                value={isMulti ? value : getValue}
                onChange={onChange}
                isDisabled={isDisabled}
                name={name}
                isMulti={isMulti}
            />
            {isInValid && <RequiredField requiredField={formikErrors} />}
        </Form.Group>
    )
}

export default SelectForm