import React, {ReactNode, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../app/modules/auth/core/action";

interface NonAuthLayoutProps {
    children: ReactNode;
}

const NonAuthLayout = ({children}: NonAuthLayoutProps) => {
    const {currentUser} = useAuth();
    const navigate = useNavigate();

    const redirectLoginFunction = () => {
        if (typeof window !== "undefined") {
            // Check if we're on the client-side
            if (!currentUser) {
                navigate("/auth/login");
            }
        }
    };

    useEffect(() => {
        redirectLoginFunction();
    });
    return <React.Fragment>{children}</React.Fragment>;
};

export default NonAuthLayout;
