import {AxiosResponse} from "axios";
import {reqDeleteFile, reqUploadFile} from "./request";

const useFile = () => {
    const uploadFile = (payload: any, appId: number) => {
        return reqUploadFile(payload, appId).then((res: AxiosResponse) => {
            return Promise.resolve(res.data.data[0].url);
        }).catch((err: AxiosResponse) => {
            return Promise.reject(err);
        })
    }
    const deleteFile = (payload: string) => {
        return reqDeleteFile(payload).then((res: AxiosResponse) => {
            return Promise.resolve(res);
        }).catch((err: AxiosResponse) => {
            return Promise.reject(err);
        })
    }

    return {uploadFile, deleteFile}
}

export {useFile}