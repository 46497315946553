import React, {Fragment, useEffect} from 'react'
import {Col, Row} from 'react-bootstrap'
import BusinessInfo from './components/business/BusinessInfo'
import MainMobile from './components/mobile/MainMobile'
import StepProgressBar from '../../../../widgets/StepProgressBar'
import ConfirmationPart from './components/business/components/ConfirmationPart'
import {useBusiness} from "../../../core/action";
import {useNavigate} from "react-router-dom";

const ReviewPage = () => {
    const {isCompleted} = useBusiness();
    const navigate = useNavigate();

    useEffect(() => {
        if (isCompleted) {
            navigate("/product-suite/add-product");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <Row className="mt-3">
                <Col lg='5'>
                    <div className='d-block d-lg-none me-4 mt-3'>
                        <StepProgressBar/>
                    </div>
                    <BusinessInfo/>
                    <span className='d-none d-lg-block'><ConfirmationPart/></span>
                </Col>
                <Col lg='7' className="mt-md-4">
                    <div className='me-4 d-none d-lg-block'>
                        <StepProgressBar/>
                    </div>
                    <MainMobile/>
                    <span className='d-block d-lg-none'><ConfirmationPart/></span>
                </Col>
            </Row>

        </Fragment>
    )
}

export default ReviewPage