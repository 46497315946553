import React, {FC} from "react";
import {Form, Image} from "react-bootstrap";
import {svgUrl} from "../../../_kiloit/helpers";

interface Props {
    placeholder?: string;
}

const SearchBar:FC<Props> = ({placeholder = ""}) => {
    return (
        <div className="searchbar-wrapper w-100 d-flex justify-content-between align-items-center bg-white border border-dark-subtle rounded-pill overflow-hidden">
            <Form.Control placeholder={placeholder} className="searchbar-control ps-3 border-0" type="text" />
            <span className="form-control-icon searchbar-icon-wrapper px-4">
                <Image className="searchbar-icon cursor-pointer py-2" src={svgUrl("search.svg")} />
            </span>
        </div>
    )
}
export default SearchBar