import React, {FC} from 'react';
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

interface Props {
    isToggled?: boolean;
    setIsToggled?: any;
}

const ButtonToProductSuite:FC<Props> = ({isToggled, setIsToggled}) => {
    const navigate = useNavigate();
    const handleClick = () => {
        isToggled && setIsToggled(!isToggled);
        navigate("/product-suite")
    }

    return (
        <Button onClick={handleClick} variant="main" className="rounded-pill px-5 py-2 mt-3">Install more products</Button>
    )
}

export default ButtonToProductSuite