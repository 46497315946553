import React, {FC, ReactNode} from "react";
import {Form, Image} from "react-bootstrap";
import clsx from "clsx";
import {RequiredField} from "./RequiredField";
import {svgUrl} from "../../../_kiloit/helpers";
import {useBusiness} from "../business/core/action";

interface Props {
    className?: string;
    placeholder?: string;
    required?: boolean;
    disabled?: boolean;
    type?: string;
    hasIcon?:boolean;
    iconPath?: string;
    iconLabel?: string;
    iconClassName?: string;
    iconOnClick?: any;
    modalWrapper?: ReactNode;
    name: string;
    label: string;
    formik: any;
}

const InputForm:FC<Props> = ({
                                 className,
                                 placeholder,
                                 required = false,
                                 disabled = false,
                                 type = "text",
                                 hasIcon = false,
                                 iconPath = "",
                                 iconClassName,
                                 iconLabel,
                                 iconOnClick,
                                 modalWrapper,
                                 name,
                                 label,
                                 formik
                             }) => {
    const formikTouched = formik.touched[name];
    const formikErrors = formik.errors[name];
    const {handleSetBusiness} = useBusiness();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (type === "tel" && /^[0-9]*$/.test(newValue)) {
            formik.setFieldValue(name, newValue);
        } else if (type !== "tel") {
            formik.setFieldValue(name, newValue);
        }
        handleSetBusiness({[name]: newValue});
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === "tel" && e.key.length === 1 && !/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <Form.Group controlId={name} className={`mt-3 ${className}`}>
            <div className="d-flex gap-3 justify-content-between align-items-center">
                <Form.Label className={clsx({"required": required})}>{label}</Form.Label>
                {hasIcon && (<span onClick={iconOnClick} className={`d-flex justify-content-end align-items-center gap-2 ${iconClassName}`}>
                    <Image src={svgUrl(iconPath)} />
                    {iconLabel}
                </span>)}
            </div>
            <Form.Control
                {...formik.getFieldProps(name)}
                name={name}
                type={type === "tel" ? "text" : type}
                placeholder={placeholder}
                disabled={disabled}
                value={formik.values[name]}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className={clsx(
                    required && { "is-valid": formikTouched && !formikErrors },
                    { "is-invalid": formikTouched && formikErrors }
                )}
            />
            {formikTouched && formikErrors && (
                <RequiredField requiredField={formikErrors} />
            )}
            {modalWrapper || null}
        </Form.Group>
    )
}

export default InputForm