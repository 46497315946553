import React from 'react';
import {Image} from "react-bootstrap";
import {svgUrl} from "../../../../_kiloit/helpers";
import ButtonToProductSuite from "./ButtonToProductSuite";

const NoAppInstalled = () => {
    return (
        <div className="w-100 text-center align-content-center min-vh-79">
            <Image className="d-block mx-auto" src={svgUrl("no-app.svg")}/>
            <ButtonToProductSuite />
        </div>
    )
}

export default NoAppInstalled