export interface AppBusinessModel {
    id: number;
    appId: number;
    name: string;
    type: string;
    status: boolean;
}
export interface CategoryModel {
    id: number;
    label: string;
    value: number;
}
export interface BusinessModel {
    appId: number | null;
    businessName: string;
    banners: string[];
    description: string;
    businessCategories: CategoryModel[];
    email: string | null;
    contactName: string;
    position: string;
    phoneNumber: string;
    address: string;
    location: LocationModel | null;
}
export interface SliderImagesModel {
    id: number;
    src: string;
}
export interface LocationModel {
    lat: number;
    lng: number;
}

// initialized Values
export const initialBusiness: BusinessModel = {
    appId: null,
    businessName: "",
    banners: [],
    description: "The description",
    businessCategories: [],
    email: "",
    contactName: "",
    position: "",
    phoneNumber: "",
    address: "",
    location: null
}