import React, {FC, Fragment, useState} from "react";
import {Button, Spinner} from "react-bootstrap";

type Props = {
    type?: "button" | "reset" | "submit";
    disable?: boolean;
    func: () => void;
    title?: string;
    btntype?: "back";
	reqLoading?: boolean | undefined;
};

const CustomBtn: FC<Props> = ({type, disable, func, title = "Submit", btntype, reqLoading}) => {
    const [loading, setLoading] = useState(false);

	const handleClick = () => {
        if (reqLoading !== undefined) return func && func();
        if (btntype === "back") {
            return func();
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            func()
        }, 1000);
	};

    let variant = "";
    switch (btntype) {
        case "back":
            variant = "light-danger";
            break;
        case undefined:
            variant = "main";
            break;
        default:
            break;
    }

    return (
        <Fragment>
            <Button
                type={type}
                variant={variant}
                onClick={handleClick}
                className="btn w-100 text-white"
                disabled={loading || reqLoading || (disable === undefined ? false : disable)}
            >
                {reqLoading ?? loading ? <Spinner animation="border" size="sm"/> : btntype ? "Back" : title}
            </Button>
        </Fragment>
    );
};

export default CustomBtn;
