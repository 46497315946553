import React, {FC} from "react";
import {Carousel, Image} from "react-bootstrap";
import {SliderImagesModel} from "../business/core/model";

interface Props {
    items: SliderImagesModel[];
    className?: string;
    indicators?: boolean;
}

const CustomCarousel:FC<Props> = ({items, className, indicators = true}) => {
    return (
        <React.Fragment>
            <Carousel indicators={indicators} className={className} controls={false}>
                {items.map((item) => (
                    <Carousel.Item key={item.id}>
                        <Image src={item.src} />
                    </Carousel.Item>
                ))}
            </Carousel>
        </React.Fragment>
    )
}

export default CustomCarousel