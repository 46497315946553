import {AuthModel, UserModel} from "./_models";
import * as authHelper from "./AuthHelpers";
import {setAllMerchants, setAuth, setLoading, setMessage} from "./reducer";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../redux/hooks/hook";
import {
    reqExchangeAccessToken,
    reqGetAllMerchants,
    reqGetUserByAccessToken,
    reqLogout,
    reqSwitchMerchant
} from "./_requests";
import {AxiosResponse} from "axios";

const useAuth = () => {
    const dispatch = useDispatch();
    const {auth, allMerchants, loading, message} = useAppSelector(state => state.auth);
    const currentUser = auth.user;

    const setCurrentUser = (value: UserModel | undefined) => dispatch(setAuth({user: value}));

    const saveAuth = (auth: AuthModel | undefined) => {
        if (auth) {
            authHelper.setAuth(auth);
            dispatch(setAuth({accessToken: auth.accessToken}));
        } else {
            authHelper.removeAuth();
            dispatch(setAuth({accessToken: undefined}));
        }
    }

    const logout = (payload: string) => {
        saveAuth(undefined);
        setCurrentUser(undefined);
        dispatch(setAllMerchants([]));
        return reqLogout(payload).then((res: AxiosResponse) => {
            console.log(res);
        }).catch((err: AxiosResponse) => {
            console.log(err);
        });
    }

    const getUserByAccessToken = () => {
        return reqGetUserByAccessToken().then((res: any) => {
            authHelper.setCurrentUser(res);
            setCurrentUser(res);
        }).catch((err: AxiosResponse) => {
            console.log(err);
        });
    }

    function getAllMerchants() {
        reqGetAllMerchants().then((res: any) => {
            dispatch(setAllMerchants(res.data.data));
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const exchangeAccessToken = async (code: string) => {
        dispatch(setLoading(true));
        try {
            const res = await reqExchangeAccessToken(code);
            saveAuth(res.data.data);
            dispatch(setLoading(false));
        } catch (err: any) {
            console.log(err);
            dispatch(setLoading(false));
            dispatch(setMessage(err?.response?.data?.error?.replaceAll("_", " ")));
        }
    };

    const switchMerchant = async (payload: UserModel) => {
        const logPayload = {
            appId: null,
            merchantId: payload.id
        };
        return await reqSwitchMerchant(logPayload).then(() => {
            authHelper.setMerchant(payload);
            getUserByAccessToken();

        }).catch((err: any) => {
            console.log(err);
        })
    }

    return {
        message,
        loading,
        logout,
        saveAuth,
        setCurrentUser,
        auth,
        currentUser,
        getUserByAccessToken,
        getAllMerchants,
        allMerchants,
        exchangeAccessToken,
        switchMerchant
    }
}

export {useAuth}